/* You can add global styles to this file, and also import other style files */
/* styles.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


* {
  font-family: 'Montserrat', sans-serif;
}

.margin-cards {
  margin-bottom: 20px;
  margin-top: 20px;
}

/* Estilos para dispositivos móveis */
@media only screen and (max-width: 600px) {
  body {
    background-color: whitesmoke;
  }
}

/* Estilo global para o ngb-pagination */
.pagination {
  --bs-pagination-color: #008000 !important;
  color: #008000 !important
}

#markdown img, svg {
  max-width: 100% !important;
}

.page-link.active,
.active>.page-link {
  background-color: green !important;
  color: white !important;
  border: 0 !important;

  &:hover {
    color: white !important;
  }
}
.page-link:hover {
  color: green !important;
}
/* Estilos para tablets */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body {
    background-color: whitesmoke;
  }
}

/* Estilos para desktops */
@media only screen and (min-height: calc(100vh - 40px)) {
  body {
    background-color: whitesmoke;
  }
}

/* body {
  background-color: whitesmoke;
} */

body>app-root>.centralize {

  background-color: whitesmoke;
}

app-root>.centralize {
  background-color: whitesmoke;

}

.toast-container {
  margin-top: 100px;
  /* Ajuste este valor conforme necessário */
}


.form-label {
  font-size: 14px;

}

.text-center .align-middle {
  font-size: 14px;
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);

}

.markdown-container img {
  max-width: 100%;
  height: auto;
}

.img-banner {
  width: 100%;
  margin-left: -50px auto 10px;
  margin-bottom: 20px;
  margin-top: 180px;

}

  /* Sortable table demo */

  /* Aplica estilo no Scroll do menu dropdown do ngb-typehead */
  ngb-typeahead-window {
    overflow-y: auto;
    max-height: 300px;
}


#typeahead-focus {
  overflow-y: auto;
}

@media only screen and (min-width: 401px) and (max-width: 600px){
  .markdown-container img {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .img-banner {
    width: 100%;
    margin-left: -50px auto 10px;
    margin-bottom: 20px;
    margin-top: 160px;

  }
}


